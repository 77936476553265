import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import he from 'he';
import { getTimeSincePublication } from '../utils/timeHelpers';
import './NewsFeed.css';
import { parseISO, parse } from 'date-fns';
import { ar } from 'date-fns/locale';

function getRandomThumbnail(config) {
  if (!config || !config.defaultThumbnailPaths || !config.defaultThumbnailPaths.length) {
    return '/images/default-thumbnail01.webp';
  }
  const randomIndex = Math.floor(Math.random() * config.defaultThumbnailPaths.length);
  return config.defaultThumbnailPaths[randomIndex];
}

function cleanText(htmlContent) {
  let sanitizedContent = DOMPurify.sanitize(htmlContent);
  sanitizedContent = sanitizedContent.replace(/(<([^>]+)>)/gi, ""); 
  sanitizedContent = sanitizedContent.replace(/(https?:\/\/[^\s]+)/g, ""); 
  sanitizedContent = sanitizedContent.trim(); 
  return he.decode(sanitizedContent);
}

function tryParseDate(dateString) {
  let parsedDate = parseISO(dateString);
  if (!isNaN(parsedDate)) return parsedDate;

  try {
    const currentYear = new Date().getFullYear();
    parsedDate = parse(`${dateString} ${currentYear}`, 'd MMMM yyyy', new Date(), { locale: ar });
    if (!isNaN(parsedDate)) return parsedDate;
  } catch (error) {
    console.error(`Failed to parse Day-Month Date: ${error}`);
  }

  parsedDate = new Date(dateString);
  if (!isNaN(parsedDate)) return parsedDate;

  console.warn(`Failed to parse date: ${dateString}`);
  return null;
}

function trimDescription(text, maxLength = 150) {
  if (text.length > maxLength) return text.slice(0, maxLength) + '...';
  return text;
}

function NewsFeed({ articles, onSelectArticle, selectedArticle, onSelectTag, onSelectSlug, config }) {
  const [processedArticles, setProcessedArticles] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initializeArticles = async () => {
      const initializedArticles = articles.map(article => ({
        ...article,
        thumbnail: article.imageUrl || getRandomThumbnail(config),  // Generate random thumbnail if not provided
      }));
      setProcessedArticles(initializedArticles);
    };

    if (articles.length > 0) {
      initializeArticles();
    }
  }, [articles, config]);

  // Handle URL slug to auto-select article
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const slug = pathParts[pathParts.length - 1];
    if (slug) {
      onSelectSlug(slug);
    }
  }, [location.pathname, onSelectSlug]);

  const handleArticleClick = (article) => {
    // Pass the article and its thumbnail to the ArticleDetails component
    onSelectArticle({ ...article, thumbnail: article.thumbnail });
    navigate(`/${article.tags[0]}/${article.slug}`);
  };

  const sortedArticles = [...processedArticles].sort((a, b) => {
    const dateA = tryParseDate(a.pubDate);
    const dateB = tryParseDate(b.pubDate);
    const timeA = dateA ? dateA.getTime() : Date.parse(a.pubDate);
    const timeB = dateB ? dateB.getTime() : Date.parse(b.pubDate);
    return timeB - timeA;
  });

  return (
    <div className="news-feed" data-testid="news-feed">
      {sortedArticles.map((article, index) => {
        const formattedDate = getTimeSincePublication(article.pubDate);
        const cleanedDescription = cleanText(article.description || article.title);
        const trimmedDesc = trimDescription(cleanedDescription);

        return (
          <div
            key={index}
            className={`article-card ${selectedArticle === article ? 'selected' : ''}`}
            onClick={() => handleArticleClick(article)}
            data-testid={`article-card-${index}`}
          >
            <div className="article-thumbnail-container" data-testid={`article-thumbnail-container-${index}`}>
              <img
                src={article.thumbnail}
                srcSet={`${article.thumbnail}?w=300 300w, ${article.thumbnail}?w=600 600w, ${article.thumbnail}?w=900 900w`}
                sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 900px"
                alt={article.title || "Article thumbnail"}
                className="article-thumbnail"
                loading="lazy"
                data-testid={`article-thumbnail-${index}`}
              />
              <div className="article-info" data-testid={`article-info-${index}`}>
                <small className="article-date" data-testid={`article-date-${index}`}>{formattedDate}</small>
                <small className="article-source" data-testid={`article-source-${index}`}>{article.source}</small>
              </div>
            </div>

            <div className="article-content" data-testid={`article-content-${index}`}>
              <h3 className="article-title" data-testid={`article-title-${index}`}>
                {cleanText(article.title)}
                {/* Tags inline with the title */}
                {article.tags && (
                  <span className="article-tags" style={{ marginLeft: '10px' }}>
                    {article.tags.map((tag, idx) => (
                      <button 
                        key={idx} 
                        className="coin-tag" 
                        onClick={(e) => {
                          e.stopPropagation();
                          onSelectTag(tag);
                        }}
                        style={{ marginRight: '5px', fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        {tag}
                      </button>
                    ))}
                  </span>
                )}
              </h3>
              <p className="article-description" data-testid={`article-description-${index}`}>{trimmedDesc}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default NewsFeed;
