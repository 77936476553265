import React from 'react';
import './TagFilterBar.css';

function TagFilterBar({ selectedTags, onRemoveTag, clearAllTags }) {
  return (
    <div className="tag-filter-bar">
      <div className="tag-filter-bar-tags">
        {selectedTags.map(tag => (
          <span key={tag} className="tag-chip">
            {tag}
            <button className="remove-tag-button" onClick={() => onRemoveTag(tag)}>×</button>
          </span>
        ))}
      </div>
      <button className="clear-all-button" onClick={clearAllTags}>
      جميع التصنيفات
      </button>
    </div>
  );
}

export default TagFilterBar;
