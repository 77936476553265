import { format, differenceInDays, parse, parseISO, isToday, startOfDay, isEqual } from 'date-fns';
import { ar } from 'date-fns/locale';

// Function to attempt multiple date parsing strategies
function tryParseDate(publishedDate) {
    let parsedDate;

    // Try to parse ISO string first
    parsedDate = parseISO(publishedDate);
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    // If ISO parsing fails, try to parse with a specific format and locale (e.g., '30 أغسطس')
    parsedDate = parse(publishedDate, 'd MMMM', new Date(), { locale: ar });
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    // Try parsing as 'DD/MM/YYYY' format (without time)
    parsedDate = parse(publishedDate, 'dd/MM/yyyy', new Date());
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    // If all parsing strategies fail, try to parse using `new Date`
    parsedDate = new Date(publishedDate);
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    // If all parsing fails, return `null`
    return null;
}

// Function to format time since publication with error handling for invalid dates
export function getTimeSincePublication(publishedDate) {
    try {
        // Parse the date using multiple strategies
        const pubDate = tryParseDate(publishedDate);

        // Handle invalid date case
        if (!pubDate) {
            throw new Error("Invalid date");
        }

        const now = new Date();

        // Check if the date has no time component (i.e., only in 'DD/MM/YYYY' format)
        if (isEqual(pubDate, startOfDay(pubDate))) {
            if (isToday(pubDate)) {
                return 'اليوم'; // Return "Today" in Arabic only for dates without time
            }
        }

        const diffInDays = differenceInDays(now, pubDate);

        // If the article was published today with time information
        if (diffInDays < 1) {
            const diffInHours = Math.floor((now - pubDate) / (1000 * 60 * 60)); // Calculate hours difference
            if (diffInHours < 1) {
                return `قبل ${Math.floor((now - pubDate) / (1000 * 60))} دقيقة`; // Return minutes if less than 1 hour
            } else {
                return `قبل ${diffInHours} ساعات`; // Return hours if less than a day
            }
        }

        // If the article was published yesterday
        else if (diffInDays === 1) {
            return 'أمس'; // "Yesterday" in Arabic
        }

        // If the article was published the day before yesterday
        else if (diffInDays === 2) {
            return 'أول أمس'; // "Day before yesterday" in Arabic
        }

        // If the article was published within the last 29 days
        else if (diffInDays <= 29) {
            return `قبل ${diffInDays} أيام`; // Return days difference
        }

        // If the article was published more than 29 days ago, format as "Day Month"
        else {
            return format(pubDate, 'd MMMM', { locale: ar });
        }
    } catch (error) {
        console.error("Error formatting publication date: ", error);
        return 'تاريخ غير معروف'; // Return "Unknown Date" in Arabic for invalid dates
    }
}
