const loadConfig = async () => {
  let configFile = 'config.dev.json'; // Default to development config

  if (process.env.NODE_ENV === 'production') {
    configFile = 'config.prod.json'; // Use production config for non-API settings
  }

  try {
    // Construct the absolute URL for fetching the config
    const baseURL = window.location.origin;

    // Since the config files are in the public folder, use the baseURL directly
    const url = `${baseURL}/${configFile}`;

    const response = await fetch(url); // Fetch from the public directory

    if (!response.ok) {
      throw new Error(`Failed to load config file: ${response.statusText}`);
    }

    const config = await response.json();

    // For production, get the API URLs and API key from environment variables (via process.env)
    if (process.env.NODE_ENV === 'production') {
      config.API_URLS = {
        CRYPTO_PRICES: process.env.REACT_APP_API_CRYPTO_PRICES || '',
        NEWS: process.env.REACT_APP_API_NEWS || '',
      };

      config.API_KEY = process.env.REACT_APP_API_KEY || ''; // Add the API key from environment variables
    } else {
      // In development, ensure the API key is loaded from config.dev.json
      if (!config.API_KEY) {
        throw new Error('API_KEY not found in config.dev.json');
      }
    }

    return config;
  } catch (error) {
    console.error('Error loading config:', error); // Log the error
    throw error;
  }
};

export default loadConfig;
