// src/components/TickerTapeWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TickerTapeWidget() {
  const container = useRef();

  useEffect(() => {
    // Check if the widget has already been added
    if (container.current.querySelector('script')) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "symbols": [
            { "proName": "BITSTAMP:BTCUSD", "title": "Bitcoin" },
            { "proName": "BITSTAMP:ETHUSD", "title": "Ethereum" },
            { "proName": "BINANCE:BNBUSDT", "title": "BNB" },
            { "proName": "GATEIO:DOGUSDT", "title": "Doge" },
            { "proName": "BINANCE:TRXUSDT", "title": "Tron" },
            { "proName": "BITSTAMP:ADAUSD", "title": "Cardano" },
            { "proName": "BITSTAMP:AVAXUSD", "title": "Avalanche" },
            { "proName": "BITSTAMP:SOLUSD", "title": "Solana" },
            { "proName": "BITSTAMP:XRPUSD", "title": "XRP" },
            { "proName": "BITSTAMP:SHIBUSD", "title": "SHIBA" },
            { "proName": "BITSTAMP:LINKUSD", "title": "ChainLink" },
            { "proName": "BITSTAMP:DOTUSD", "title": "Polkadot" },
            { "proName": "BITSTAMP:NEARUSD", "title": "NEAR" },
            { "proName": "BITSTAMP:LTCUSD", "title": "Litecoin" }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "dark",
        "locale": "ar_AE"
      }`;
    container.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container} data-testid="ticker-tape-widget">
      {/* Only keep the container for the widget */}
      {/* <div className="tradingview-widget-copyright">
        <a href="https://ar.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">تتبع جميع الأسواق على TradingView</span>
        </a> */}
      {/* </div> */}
    </div>
  );
}

export default memo(TickerTapeWidget);
