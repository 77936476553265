// src/utils/localStorageUtils.js

const CACHE_KEY = 'cachedArticles';
const CACHE_TIMESTAMP_KEY = 'cachedTimestamp';

// Function to save articles to localStorage
export const saveArticlesToCache = (articles) => {
    try {
        localStorage.setItem(CACHE_KEY, JSON.stringify(articles));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now());
    } catch (error) {
        console.error("Error saving articles to cache:", error);
    }
};

// Function to retrieve cached articles from localStorage
export const getCachedArticles = () => {
    try {
        const cachedArticles = JSON.parse(localStorage.getItem(CACHE_KEY));
        const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
        return { cachedArticles, cachedTimestamp };
    } catch (error) {
        console.error("Error retrieving articles from cache:", error);
        return { cachedArticles: null, cachedTimestamp: null };
    }
};

// Function to check if the cache is still valid
export const isCacheValid = (cachedTimestamp, cacheExpiration) => {
    if (!cachedTimestamp) return false;
    const now = Date.now();
    return now - cachedTimestamp < cacheExpiration;
};

// Function to clear the cache (useful for testing or when cache needs to be invalidated)
export const clearCache = () => {
    try {
        localStorage.removeItem(CACHE_KEY);
        localStorage.removeItem(CACHE_TIMESTAMP_KEY);
    } catch (error) {
        console.error("Error clearing the cache:", error);
    }
};
