const whoWeAreText = `
موقع "CamelBits"، هو موقع عربي متخصص في مجال أخبار العملات الرقمية الهامة، ونسعى لتزويد المستخدم العربي بأخر البيانات و الأخبار التي يحتاجها لمعرفة اهم الاخبار بعالم العملات الرقمية.
في "CamelBits"، نبتكر ونتعلم بشغف لتحقيق النجاح والتميز، وخدماتنا تواكب الزمن في مختلف المجالات التي نقدمها لأعضاء موقعنا.
نقوم بتجميع أخر و أهم الأنباء في موقع "CamelBits"، من أشهر المواقع العربية و نطمح أن نكون الموقع الأول للمهتمين بأخبار العملات الرقمية المجمعة في العالم العربي، من خلال تقديم الأخبار الهامة و المميزه من أهم المواقع الأخباريه بمكان واحد والعمل بإبداع واحترافية.
قوتنا تكمن في تجميع كل ما يحتاجه المستخدم من الاخبار التى تساعده على الاضطلاع السريع على السوق من خلال الاخبار التى قمنا بتجميعها بمكان واحد، وهي التي تجعلنا مختلفين.
انضمامك إلينا سيساهم في تطوير مجتمع العملات الرقمية في العالم العربي.
كن جزء من مجمتعنا لنشر التوعيه و الاخبار الهامة التى تساعد على إنتشار العملات الرقمية بوطننا العربي.
`;

export default whoWeAreText;
