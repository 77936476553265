const privacyPolicyText = `
هذه سياسات موقع CamelBits وباستخدامك له فإنك توافق على الشروط والأحكام التالية:
جميع المحتويات المعروضة من مقالات كتابية أو مرئية على الموقع، سواء كانت إخبارية أو إعلانية أو مقالات توضيحية للمفاهيم، فإن هدفها الأساسي هو التعليم وزيادة المعرفة وتثقيف المستخدم العربي حول مجال العملات الرقمية المشفرة.
لذلك، نحن غير مسؤولين بأي شكل من الأشكال عن كيفية استخدام هذه المعلومات، بما في ذلك التعاملات التجارية أو المالية.
المسؤولية الكاملة تقع على عاتق المستخدم، ولا نتحمل أي مسؤولية عن كيفية استخدامك للمعلومات.
أي خسارة تحدث، فهي على مسؤوليتك الشخصية بالكامل، ولا يمكن تحميلنا المسؤولية عنها.
المحفظة هي محفظتك، والأموال هي أموالك، ونحن غير مسؤولين عنها بأي شكل فيما يتعلق بالمصادر والعلامات التجارية، فهي تعود ملكيتها لأصحابها الأصليين.
المقالات المنشورة في موقع CamelBits تعبر عن آراء كتابها ولا تعبر عن وجهة نظر الموقع.
الموقع غير مسؤول عن أي أضرار قد تحدث نتيجة لاستخدامك لهذه المقالات.
الموقع غير مسؤول عن كيفية استثمار أو إدارة أموالك، ولا يتحمل أي مسؤولية عن أي خسائر قد تصيبها.
موقع CamelBits قد ينشر إعلانات أو مقالات ممولة من طرف ثالث، وبالتالي نبرئ مسؤوليتنا عن أي أضرار قد تنجم عن استخدام المحتوى الإعلاني.
يُنصح المستخدم بإجراء بحثه الخاص قبل اتخاذ أي خطوة. الشروط والأحكام المذكورة أعلاه تنطبق على أي مستخدم للموقع.`;

export default privacyPolicyText;
