import React, { useState, useEffect } from 'react';
import './CryptoPriceSidebar.css'; // Assuming you will add styles for the sidebar
import loadConfig from '../config/config'; // Import the configuration file loader

function CryptoPriceSidebar({ closeAllAccordions, onSearch }) {
  const [coins, setCoins] = useState([]);
  const [activeTab, setActiveTab] = useState('hourly');

  useEffect(() => {
    // Fetch config and coins data from the backend
    const fetchConfigAndCoins = async () => {
      try {
        // Load config dynamically
        const loadedConfig = await loadConfig();

        // Ensure API_URLS and CRYPTO_PRICES exist in the config
        if (!loadedConfig?.API_URLS?.CRYPTO_PRICES) {
          throw new Error('CRYPTO_PRICES API URL is missing from the configuration.');
        }

        const apiKey = loadedConfig.API_KEY; // Get the API key from config

        // Fetch coins from the backend using the config, including the API key in the headers
        const response = await fetch(loadedConfig.API_URLS.CRYPTO_PRICES, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey, // Include API key in request headers
          },
        });

        // Check if response is JSON
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Response is not JSON');
        }

        const data = await response.json();
        setCoins(data);
      } catch (error) {
        console.error('Error fetching cached coin data:', error);
      }
    };

    fetchConfigAndCoins();
  }, []);

  // Handle click on the crypto logo
  const handleCryptoLogoClick = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    // Clear the URL (set to the main page)
    window.history.replaceState(null, '', '/');

    // Close all accordion items
    if (closeAllAccordions) {
      closeAllAccordions();
    }

    // Clear the search input in the Navbar
    if (onSearch) {
      onSearch(''); // Clear the search input by resetting the search query
    }
  };

  const renderCoinData = (coin) => {
    // Determine which price change percentage to display based on the active tab
    const priceChange =
      activeTab === 'hourly'
        ? coin.price_change_percentage_1h_in_currency
        : activeTab === 'daily'
        ? coin.price_change_percentage_24h_in_currency
        : coin.price_change_percentage_7d_in_currency;

    // Determine the color and arrow based on the price change
    const symbolColor = priceChange > 0 ? 'green' : priceChange < 0 ? 'red' : 'black';
    const arrow = priceChange > 0 ? '↑' : priceChange < 0 ? '↓' : ''; // Up or down arrow based on price change

    return (
      <div key={coin.id} className="crypto-item" data-testid={`crypto-item-${coin.symbol}`}>
        {/* Coin symbol with dynamic color and arrow */}
        <span
          className="crypto-symbol"
          style={{ color: symbolColor }}
          data-testid={`crypto-symbol-${coin.symbol}`}
        >
          {coin.symbol.toUpperCase()} {arrow}
        </span>
        <span className="crypto-price" data-testid={`crypto-price-${coin.symbol}`}>
          ${coin.current_price.toFixed(2)}
        </span>
        <span
          className={`crypto-change ${priceChange >= 0 ? 'positive' : 'negative'}`}
          data-testid={`crypto-change-${coin.symbol}`}
        >
          {priceChange ? `${priceChange.toFixed(2)}%` : 'N/A'}
        </span>
      </div>
    );
  };

  return (
    <div className="crypto-sidebar" data-testid="crypto-sidebar">
      {/* Logo added here with relative path from the public folder */}
      <img
        src="/images/logo.webp"
        alt="Camel Bits Logo"
        className="crypto-logo"
        data-testid="crypto-logo"
        onClick={handleCryptoLogoClick} // Add the click handler here
      />

      {/* Tabs in Arabic */}
      <div className="crypto-tabs" data-testid="crypto-tabs">
        <button
          className={activeTab === 'hourly' ? 'active' : ''}
          onClick={() => setActiveTab('hourly')}
          data-testid="tab-hourly"
        >
          ساعة
        </button>
        <button
          className={activeTab === 'daily' ? 'active' : ''}
          onClick={() => setActiveTab('daily')}
          data-testid="tab-daily"
        >
          يوم
        </button>
        <button
          className={activeTab === 'weekly' ? 'active' : ''}
          onClick={() => setActiveTab('weekly')}
          data-testid="tab-weekly"
        >
          أسبوع
        </button>
      </div>

      <div className="crypto-data" data-testid="crypto-data">
        {coins.length > 0 ? (
          coins.map(renderCoinData)
        ) : (
          <p data-testid="loading-message">Loading...</p>
        )}
      </div>
    </div>
  );
}

export default CryptoPriceSidebar;
