import React, { useState, useEffect, useContext } from 'react';
import './Navbar.css';
import whoWeAreText from '../text/WhoWeAreText';
import privacyPolicyText from '../text/PrivacyPolicyText';
import ThemeContext from '../ThemeContext'; // Import ThemeContext
import { FaSun, FaMoon } from 'react-icons/fa'; // Import sun and moon icons

function Navbar({ onShowAllNews, onShowLatestNews, isMobile, onSearch, closeAllAccordions }) {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Access dark mode state and toggle function
  const [searchQuery, setSearchQuery] = useState('');
  const [isWhoWeAreOpen, setWhoWeAreOpen] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false); // State to handle the Hamburger menu

  // Modal toggle functions
  const toggleWhoWeAre = () => {
    setWhoWeAreOpen(!isWhoWeAreOpen);
    setPrivacyOpen(false); // Close the privacy modal if it's open
  };

  const togglePrivacy = () => {
    setPrivacyOpen(!isPrivacyOpen);
    setWhoWeAreOpen(false); // Close the "Who We Are" modal if it's open
  };

  const toggleMenu = () => setMenuOpen(!isMenuOpen); // Toggle the Hamburger menu

  // Close the burger menu on click anywhere in the document
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMenuOpen && e.target.closest('.hamburger-menu') === null) {
        setMenuOpen(false); // Collapse the menu when clicking outside the hamburger
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);    // Update local state
    onSearch(query);          // Send query to parent component (App.js)
  };

  // Add useEffect to listen for external changes in search query (like from the crypto logo click)
  useEffect(() => {
    if (searchQuery === '') {
      setSearchQuery(''); // Clear search input when searchQuery is empty (triggered from outside)
    }
  }, [searchQuery]);

  // Handle mobile navbar brand click
  const handleBrandClick = () => {
    // Clear search query
    setSearchQuery('');
    onSearch(''); // Clear search in parent component

    // Clear the URL to make it the main page (root URL)
    window.history.replaceState(null, '', '/');

    // Scroll to the top of the page (with smooth behavior)
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Ensure smooth scrolling to the top
    });

    // Close all accordion items (if applicable)
    if (closeAllAccordions) {
      closeAllAccordions();
    }

    // Optionally close the menu
    setMenuOpen(false);
  };

  return (
    <>
      {/* Desktop Navbar */}
      {!isMobile && (
        <nav className="navbar" data-testid="desktop-navbar">
          <ul>
            {/* Dark Mode Toggle */}
            <li onClick={toggleTheme} className="theme-toggle">
              {isDarkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
            </li>
            {/* Social Icons - Twitter and Telegram */}
            <div className="social-icons">
              <a href="https://x.com/CamelBits" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <svg className="icon icon-twitter" viewBox="0 0 24 24">
                  <path d="M22.46 6c-.77.35-1.6.58-2.46.69.89-.53 1.57-1.37 1.88-2.37-.83.5-1.76.87-2.74 1.06a4.26 4.26 0 0 0-7.26 3.87c-3.54-.17-6.7-1.87-8.8-4.46a4.24 4.24 0 0 0 1.32 5.68 4.28 4.28 0 0 1-1.93-.53v.05a4.25 4.25 0 0 0 3.4 4.17 4.28 4.28 0 0 1-1.92.07 4.26 4.26 0 0 0 3.97 2.95 8.54 8.54 0 0 1-5.29 1.82c-.34 0-.68-.02-1.01-.06a12.04 12.04 0 0 0 6.5 1.9c7.8 0 12.07-6.47 12.07-12.08 0-.18-.01-.35-.01-.52.83-.6 1.56-1.34 2.14-2.2z"></path>
                </svg>
              </a>
              <a href="https://t.me/CamelBits" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                <svg className="icon icon-telegram" viewBox="0 0 24 24">
                  <path d="M22.87 2.12a2.23 2.23 0 0 0-2.3-.33l-18.07 7.5a1.5 1.5 0 0 0-.09 2.74l4.83 2.02 1.67 5.73a1.5 1.5 0 0 0 2.38.73l3.38-2.87 4.92 3.56a1.5 1.5 0 0 0 2.38-.88l3.04-14.76a2.23 2.23 0 0 0-.74-2.44zM15.55 17.27l-3.94-2.85a1.5 1.5 0 0 0-1.86.07l-2.79 2.36-.98-3.37 10.6-7.45-6.76 8.2a.75.75 0 0 0 .93 1.16l4.8 1.95z"></path>
                </svg>
              </a>
            </div>
            {/* Add Search input */}
            <div className="navbar-search">
              <input
                type="text"
                placeholder="ابحث عن مقالة...🔎"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <li onClick={togglePrivacy} data-testid="desktop-nav-privacy">سياسة الخصوصية و المسؤولية</li>
            <li onClick={toggleWhoWeAre} data-testid="desktop-nav-who-we-are">من نحن</li>
            <li onClick={onShowLatestNews} data-testid="desktop-nav-latest-news">🔥أخر الأخبار</li>
            <li onClick={onShowAllNews} data-testid="desktop-nav-all-news">💎جميع الأخبار</li>
          </ul>
        </nav>
      )}

      {/* Mobile Navbar with Hamburger */}
      {isMobile && (
        <nav className="mobile-navbar" data-testid="mobile-navbar">
          <div className="hamburger-menu" data-testid="hamburger-menu">
            <div className="hamburger-icon" onClick={toggleMenu} data-testid="hamburger-icon">☰</div> {/* Hamburger Icon */}
            {/* Social Icons in Mobile */}
            <div className="social-icons-mobile">
              <a href="https://x.com/CamelBits" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <svg className="icon icon-twitter" viewBox="0 0 24 24">
                  <path d="M22.46 6c-.77.35-1.6.58-2.46.69.89-.53 1.57-1.37 1.88-2.37-.83.5-1.76.87-2.74 1.06a4.26 4.26 0 0 0-7.26 3.87c-3.54-.17-6.7-1.87-8.8-4.46a4.24 4.24 0 0 0 1.32 5.68 4.28 4.28 0 0 1-1.93-.53v.05a4.25 4.25 0 0 0 3.4 4.17 4.28 4.28 0 0 1-1.92.07 4.26 4.26 0 0 0 3.97 2.95 8.54 8.54 0 0 1-5.29 1.82c-.34 0-.68-.02-1.01-.06a12.04 12.04 0 0 0 6.5 1.9c7.8 0 12.07-6.47 12.07-12.08 0-.18-.01-.35-.01-.52.83-.6 1.56-1.34 2.14-2.2z"></path>
                </svg>
              </a>
              <a href="https://t.me/CamelBits" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
                <svg className="icon icon-telegram" viewBox="0 0 24 24">
                  <path d="M22.87 2.12a2.23 2.23 0 0 0-2.3-.33l-18.07 7.5a1.5 1.5 0 0 0-.09 2.74l4.83 2.02 1.67 5.73a1.5 1.5 0 0 0 2.38.73l3.38-2.87 4.92 3.56a1.5 1.5 0 0 0 2.38-.88l3.04-14.76a2.23 2.23 0 0 0-.74-2.44zM15.55 17.27l-3.94-2.85a1.5 1.5 0 0 0-1.86.07l-2.79 2.36-.98-3.37 10.6-7.45-6.76 8.2a.75.75 0 0 0 .93 1.16l4.8 1.95z"></path>
                </svg>
              </a>
            </div>
            <span className="mobile-navbar-brand" onClick={handleBrandClick} data-testid="mobile-navbar-brand">
              CamelBits.com {/* Brand Text */}
              <img
                src="/images/brand-icon.webp"
                alt="Brand Icon"
                className="brand-image"
                data-testid="mobile-brand-image"
              /> {/* Brand Image */}
            </span>
          </div>
          {isMenuOpen && (
            <div className="mobile-menu" data-testid="mobile-menu">
              <ul>
                <li onClick={() => { onShowAllNews(); setMenuOpen(false); }} data-testid="mobile-nav-all-news">جميع الأخبار</li>
                <li onClick={() => { onShowLatestNews(); setMenuOpen(false); }} data-testid="mobile-nav-latest-news">🔥أخر الأخبار</li>
                <li onClick={() => { toggleWhoWeAre(); setMenuOpen(false); }} data-testid="mobile-nav-who-we-are">من نحن</li>
                <li onClick={() => { togglePrivacy(); setMenuOpen(false); }} data-testid="mobile-nav-privacy">سياسة الخصوصية و المسؤولية</li>
                {/* Dark Mode Toggle */}
                <li onClick={() => { toggleTheme(); setMenuOpen(false); }} className="theme-toggle">
                  {isDarkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
                </li>
              </ul>
            </div>
          )}
        </nav>
      )}

      {/* Modal for "من نحن" */}
      {isWhoWeAreOpen && (
        <div className="modal" onClick={toggleWhoWeAre} data-testid="who-we-are-modal">
          <div className="modal-content" onClick={e => e.stopPropagation()} data-testid="who-we-are-modal-content">
            <h2>من نحن</h2>
            <p>{whoWeAreText}</p>
            <button onClick={toggleWhoWeAre} data-testid="who-we-are-close-btn">إغلاق</button>
          </div>
        </div>
      )}

      {/* Modal for "سياسة الخصوصية و المسؤولية" */}
      {isPrivacyOpen && (
        <div className="modal" onClick={togglePrivacy} data-testid="privacy-modal">
          <div className="modal-content" onClick={e => e.stopPropagation()} data-testid="privacy-modal-content">
            <h2>سياسة الخصوصية و المسؤولية</h2>
            <p>{privacyPolicyText}</p>
            <button onClick={togglePrivacy} data-testid="privacy-close-btn">أوافق على الشروط</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
